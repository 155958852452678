<template>
  <v-container fluid>
    <v-card
      tile
    >
      <v-tabs
        v-model="tabsModel"
        color="primary"
      >
        <v-tab
          key="1"
          :href="'#tab-single'"
        >
          {{ $vuetify.lang.t('$vuetify.equipment.store') }}
        </v-tab>

        <v-tab
          key="2"
          :href="'#tab-batch'"
        >
          {{ $vuetify.lang.t('$vuetify.equipment.batchImport') }}
        </v-tab>

        <v-tab-item
          key="store"
          :value="'tab-single'"
        >
          <v-container
            key="equTemplate"
            fluid
          >
            <v-subheader>{{ $vuetify.lang.t('$vuetify.equipmentTemplate.label') }}</v-subheader>
            <v-card
              flat
            >
              <v-card-text>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      :label="$vuetify.lang.t('$vuetify.equipmentTemplate.name')"
                      :value="equipmentTemplateSelected ? equipmentTemplateSelected.name : null"
                      append-icon="mdi-magnify"
                      clearable
                      @click="handleSearchClick"
                      @click:clear="handleClearClick"
                    >
                    </v-text-field>

                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>

          <v-divider></v-divider>

          <v-container
            key="equForm"
            fluid
          >
            <v-subheader>{{ $vuetify.lang.t('$vuetify.equipment.store') }}</v-subheader>
            <base-form
              @submit="handleSubmit"
              @cancel="handleCancel"
            />
          </v-container>
        </v-tab-item>
        <v-tab-item
          key="batchImport"
          :value="'tab-batch'"
        >
          <v-container
            key="equTemplate"
            fluid
          >
            <v-subheader>{{ $vuetify.lang.t('$vuetify.equipmentTemplate.label') }}</v-subheader>
            <v-card
              flat
            >
              <v-card-text>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="12"
                  >
                    <v-text-field
                      :label="$vuetify.lang.t('$vuetify.equipmentTemplate.name')"
                      :value="equipmentTemplateSelected ? equipmentTemplateSelected.name : null"
                      append-icon="mdi-magnify"
                      clearable
                      @click="handleSearchClick"
                      @click:clear="handleClearClick"
                    >
                    </v-text-field>

                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>

          <v-divider></v-divider>

          <v-container
            key="equForm"
            fluid
          >
            <v-subheader>
              {{ $vuetify.lang.t('$vuetify.equipment.uploadList') }}
            </v-subheader>
            <v-card
              flat
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="6"
                  >
                    <v-form
                      ref="form"
                    >
                      <v-file-input
                        v-model="form.uploadFile"
                        :label="$vuetify.lang.t('$vuetify.common.chooseFiles')"
                        :rules="rules.uploadFile"
                        chips
                      ></v-file-input>
                    </v-form>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <div
                      class="mt-8"
                    >
                      {{$vuetify.lang.t('$vuetify.equipment.downloadTip')}}
                      <v-btn
                        color="blue"
                        text
                        @click="handleDownloadTemplate"
                      >
                         {{$vuetify.lang.t('$vuetify.common.clickDownload')}}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="info darken-1"
                  @click="handleCancel"
                >
                   {{$vuetify.lang.t('$vuetify.common.cancel')}}
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  @click="handleBatchSubmit"
                >
                   {{$vuetify.lang.t('$vuetify.common.submit')}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-tab-item>
      </v-tabs>
      <v-dialog
        v-model="dialogTemplateList"
        persistent
        max-width="800px"
      >
        <template-list
          v-if="dialogTemplateList"
          @submit="handleTepmalteListSubmit"
          @cancel="handleTepmalteListCancel"
        />
      </v-dialog>

      <v-dialog
        v-model="chooseTemplateDialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            {{ $vuetify.lang.t('$vuetify.common.warning') }}
          </v-card-title>
          <v-card-text>
            {{ $vuetify.lang.t('$vuetify.equipment.chooseEquipmentTemplate') }} !
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info darken-1"
              @click="chooseTemplateDialog = false"
            >
              {{ $vuetify.lang.t('$vuetify.common.ok') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="chooseUploadDialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            {{ $vuetify.lang.t('$vuetify.common.warning') }}
          </v-card-title>
          <v-card-text>
            {{ $vuetify.lang.t('$vuetify.common.chooseFiles') }} !
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="info darken-1"
              @click="chooseUploadDialog = false"
            >
              {{ $vuetify.lang.t('$vuetify.common.ok') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
   </v-container>
</template>

<script>

  import { mapActions } from 'vuex';
  import dialogs from '@/services/dialogs.js';
  import { getLocalMessage } from '@/utils';
  import { excelFileName } from '@/utils/validators';
  import { saveAs } from 'file-saver'

  export default {
    components: {
      TemplateList: () => import('./template'),
      BaseForm: () => import('./BaseForm')
    },
    data () {
      return {
        tabsModel: 'tab-single',
        dialogTemplateList: false,
        chooseTemplateDialog: false,
        chooseUploadDialog: false,
        equipmentTemplateSelected: null,
        form: {
          uploadFile: null
        },

        rules: {
          uploadFile: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.common.chooseFiles')]),
            (v) =>
              ( v && excelFileName.test(v.name)) || this.$vuetify.lang.t('$vuetify.rule.mustIsExcel')
          ]
        }
      }
    },

    created () {
      if (this.$route.params.patch) {
        this.tabsModel = this.$route.params.patch
      }
    },

    methods: {
      ...mapActions({
        equipmentCreate: 'equipment/create',
        downloadTemplate: 'equipment/downloadTemplate',
        importById: 'equipment/importById',
      }),

      getLocalMessage,

      handleSearchClick() {
        this.dialogTemplateList = true
      },
      handleTepmalteListSubmit (data) {
        this.equipmentTemplateSelected = data
        this.dialogTemplateList = false
      },
      handleTepmalteListCancel () {
        this.dialogTemplateList = false
      },
      handleCancel () {
        this.$router.go(-1)
      },

      handleDownloadTemplate () {
        this.downloadTemplate().then( (response) => {
          saveAs(new Blob([response.data], { type: 'application/octet-stream' }), `${ this.$vuetify.lang.t('$vuetify.equipmentTemplate.label') }.xlsx`)
        })
      },
      handleClearClick () {
        this.equipmentTemplateSelected = null
      },
      handleSubmit (data) {
        if ( this.equipmentTemplateSelected ) {
          data.emId = this.equipmentTemplateSelected.id
          this.equipmentCreate(data).then( () => {
            this.$router.push({name: 'equipmentList'})
          }).catch( error => {
            if (error.data && error.data.code == 1100) {
              let messages = error.data.message.split('\'')
              dialogs.message(this.$vuetify.lang.t('$vuetify.message.resourceExistedErr', [this.getLocalMessage(this, 'equipment', messages[1])]), { duration: 10, type: 'error' })
            } else {
              dialogs.message(this.$vuetify.lang.t('$vuetify.message.systemErr'), { duration: 10, type: 'error' })
            }
          })
        } else {
          this.chooseTemplateDialog = true
        }
      },
      handleBatchSubmit () {
        if ( !this.equipmentTemplateSelected ) {
          this.chooseTemplateDialog = true
          return false
        }
        if ( !this.$refs.form.validate() ) {
          return false
        }
        const formData = new FormData()
        formData.append('file', this.form.uploadFile)
        this.importById({ id: this.equipmentTemplateSelected.id, data: formData}).then( (data) => {
          if (data.data.successNum === 0) {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.importTooltips', data.data.successNum, data.data.failedNum), { duration: 10, type: 'error' })
          } else if (data.data.failedNum === 0) {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.importTooltips', data.data.successNum, data.data.failedNum), { duration: 10, type: 'success' }, () => this.$router.push( { name: 'equipmentList' }))
          } else {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.importTooltips', data.data.successNum, data.data.failedNum), { duration: 10, type: 'warning' })
          }
        }).catch( () => {
          dialogs.message(this.$vuetify.lang.t('$vuetify.message.systemErr'), { duration: 10, type: 'error' })
        })
      }
    },
  }
</script>
